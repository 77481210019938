.timestamp {
  cursor: pointer;
  color: #53535f;
  font-variant-numeric: tabular-nums;
}

:global(.dark) .timestamp {
  color: #adadb8;
}

.author {
  color: #5c16c5;
}

:global(.dark) .author {
  color: #bf94ff;
}

.username {
  opacity: 0.7;
}

.message {
  color: #1f1f23;
}

:global(.dark) .message {
  color: #dedee3;
}
