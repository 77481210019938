html {
  font-size: 62.5%;
}

body {
  margin: 0;
  font-size: 1.3rem;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.dark {
  background-color: #18181b;
}

.max-vh-100 {
  max-height: 100vh;
}

.f-14 {
  font-size: 1.4rem;
}

.f-16 {
  font-size: 1.6rem;
}
